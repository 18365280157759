import React, { useState, useEffect, createContext, lazy } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import { ConfigProvider, message } from 'antd'
import zh_cn from 'antd/locale/zh_CN'
import eng from 'antd/locale/en_US'
import 'dayjs/locale/zh-cn'
import { IntlProvider } from 'react-intl'
import zh_CN from './static/locale/zh-CN'
import en_US from './static/locale/en-US'
import PrivateRoute from './pages/PrivateRoute/privateRoute'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { DndProvider } from 'react-dnd'
import './App.css'
import http from './utils/http'
import 'tailwindcss/tailwind.css'

// const Main = lazy(() => import('./pages/main/main'))
const Login = lazy(() => import('./pages/login/login'))
const Header = lazy(() => import('./pages/header/index'))

// const ForgotPassword = lazy(() => import('./pages/forgotPassword/index'))
// const Signup = lazy(() => import('./pages/signup/index'))
const AiTestRuns = React.lazy(() => import('./pages/aiTestRuns/index'))

export const myContext = createContext({
  locale: '',
  languageChange: () => {},
  onLogin: () => {},
  onGoogleLogin: () => {},
})

function App() {
  const [locale, setLocale] = useState('en')
  const [auth, setAuth] = useState(false)
  const navigate = useNavigate()
  // const [userinfo, setUserinfo] = useState({})

  useEffect(() => {
    let lang = ''
    if (localStorage.getItem('locale')) {
      lang = localStorage.getItem('locale')
    } else {
      lang = 'zh'
      localStorage.setItem('locale', lang)
    }
    if (lang === 'zh') {
      setLocale('en')
      // moment.locale('zh-cn')
    } else {
      setLocale('en')
      // moment.locale('en')
    }
  }, [])

  const languageChange = (lang) => {
    setLocale(lang)
    localStorage.setItem('locale', lang)
  }

  const onGoogleLogin = (access_token) => {
    if (access_token) {
      http
        .get('/api/auth/googleLogin?accessToken=' + access_token)
        .then((res) => {
          setAuth(access_token ? true : false)
          navigate('/createInterview')
          localStorage.setItem('auth', access_token ? true : false)
          // 用户信息
          http.get('api/auth/userinfo').then((res) => {
            // setUserinfo(res)
            let data = JSON.stringify(res)
            localStorage.setItem('userinfo', data ? data : '')
          })
        })
        .catch((res) => {
          message.error(res.response.data.msg)
        })
    }
  }
  const onLogin = (obj) => {
    if (obj) {
      http
        .post('/api/auth/login', obj)
        .then((res) => {
          setAuth(obj ? true : false)
          navigate('/createInterview')
          localStorage.setItem('auth', obj ? true : false)
          // 用户信息
          http.get('api/auth/userinfo').then((res) => {
            // setUserinfo(res)
            let data = JSON.stringify(res)
            localStorage.setItem('userinfo', data ? data : '')
          })
        })
        .catch((res) => {
          message.error(res.response.data.msg)
        })
    }
  }
  const contextVal = {
    languageChange,
    locale,
    onLogin,
    onGoogleLogin,
  }

  const messages = {
    en: en_US,
    zh: zh_CN,
  }
  return (
    //  <DndProvider backend={HTML5Backend}>
    //     <Card />
    //   </DndProvider>

    <IntlProvider
      locale={locale === 'zh' ? 'zh' : 'en'}
      messages={messages[locale]}
    >
      <ConfigProvider locale={locale === 'zh' ? zh_cn : eng}>
        <myContext.Provider value={contextVal}>
          <Routes>
            <Route element={<PrivateRoute authed={auth} />}>
              <Route path="/" element={<Navigate to="/createInterview" />} />
              <Route path="/*" element={<Header />} />
              {/* <Route path="/*" element={<Main />} /> */}
            </Route>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            {/* <Route path="/signup" element={<Signup />} /> */}
            <Route path="/aiTestRuns" element={<AiTestRuns />} />
          </Routes>
        </myContext.Provider>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default App
